import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { IconColor } from '@widgets/eop-icon';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { DropdownOverlayDirective } from './dropdown-overlay/dropdown-overlay.directive';
import { DropdownItem } from './dropdown-type';

@Component({
  selector: 'emob-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements ControlValueAccessor, OnInit, OnDestroy, OnChanges {
  @Input() items: DropdownItem[] = [];
  @Input() singleSelectDeselactable = true;
  @Input() searchable = false;
  @Input() itemTemplate!: TemplateRef<any>;
  @Input() selectedItemTemplate!: TemplateRef<any>;
  @Input() errorTemplate!: TemplateRef<any>;
  @Input() placeholder = '';
  @Input() noResultText = 'No result...';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() multiSelect = false;

  isSearching = false;
  isOverlayOpen = false;
  control = new FormControl();
  propagateTouched: VoidFunction | null = null;
  propagateChange: ((_: DropdownItem[] | DropdownItem) => {}) | null = null;
  activeItemIndex: number | null = null;
  selectedItems: DropdownItem[] = [];
  @ViewChild('input', { static: true }) input!: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;
  @ViewChild(DropdownOverlayDirective, { static: true })
  dropdownDirective!: DropdownOverlayDirective;

  searchPlaceholder = this.placeholder;
  private readonly unsubscribe$ = new Subject<void>();
  readonly IconColor = IconColor;

  constructor(
    @Self()
    @Optional()
    public controlDir: NgControl,
    @Inject(DOCUMENT) public document: any
  ) {
    if (this.controlDir) {
      this.controlDir.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.placeholder) {
      this.searchPlaceholder = this.placeholder;
    }
  }

  ngOnInit(): void {
    if (this.controlDir && this.controlDir.control) {
      this.control.validator = this.controlDir.control.validator;
      this.control.asyncValidator = this.controlDir.control.asyncValidator;
      this.control.updateValueAndValidity();
    }
    this.control.valueChanges
      .pipe(
        filter(
          value =>
            value !== null &&
            value !== undefined &&
            value !== this.placeholder &&
            value !== '' &&
            value !== this.controlDir?.value?.name
        ),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.openOverlay();
        if (this.searchable && this.viewport) {
          this.viewport.scrollToIndex(0);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSelectedItemChange(item: DropdownItem): void {
    if (this.multiSelect) {
      const index = this.selectedItems.findIndex(selectedItem => selectedItem.name === item.name);
      if (index === -1) {
        this.selectedItems.push(item);
      } else {
        this.selectedItems.splice(index, 1);
      }
      this.control.markAsDirty();
      this.control.setValue(this.selectedItems.map(selectedItem => selectedItem.name).join(', '), {
        emitEvent: false,
      });
      if (this.propagateChange) {
        this.propagateChange(this.selectedItems);
      }
    } else {
      this.selectedItems = [item];
      this.control.markAsDirty();
      this.control.setValue(item.name, { emitEvent: false });
      if (item.name === '') {
        this.searchPlaceholder = this.placeholder;
      }
      if (this.propagateChange) {
        this.propagateChange(item);
      }
      this.calculateActiveIndex();
    }
  }

  onTouched(): void {
    this.control.markAsTouched();
    if (this.propagateTouched) {
      this.propagateTouched();
    }
    this.onInputClick(this.isOverlayOpen);
  }

  registerOnChange(fn: (_: DropdownItem[] | DropdownItem) => {}): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: VoidFunction): void {
    this.propagateTouched = fn;
  }

  writeValue(obj: DropdownItem[] | DropdownItem | undefined): void {
    if (this.multiSelect && Array.isArray(obj)) {
      this.selectedItems = obj;
      this.control.setValue(this.selectedItems.map(item => item.name).join(', '));
    } else if (obj) {
      this.control.setValue((obj as DropdownItem).name);
      this.selectedItems = [obj as DropdownItem];
    } else {
      this.control.setValue('');
      this.selectedItems = [];
    }
    this.searchPlaceholder = this.placeholder;
    this.calculateActiveIndex();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  deselectItem(item: DropdownItem): void {
    const index = this.selectedItems.findIndex(selectedItem => selectedItem.name === item.name);
    if (index !== -1) {
      this.selectedItems.splice(index, 1);
      this.control.markAsDirty();
      this.control.setValue(this.selectedItems.map(selectedItem => selectedItem.name).join(', '), {
        emitEvent: false,
      });
      if (this.propagateChange) {
        this.propagateChange(this.selectedItems);
      }
    }
  }

  isSelected(item: DropdownItem): boolean {
    return this.selectedItems.some(selectedItem => selectedItem.name === item.name);
  }

  openOverlay(): void {
    if (!this.isOverlayOpen) {
      this.dropdownDirective.emitOverlayOpenSignal();
    }
  }

  onOpenOverlay(event: boolean | DropdownItem): void {
    if (typeof event !== 'boolean') {
      return;
    }
    if (!event) {
      this.isSearching = false;
      this.calculateActiveIndex();
    }
    this.isOverlayOpen = event;
    this.onInputClick(event);
  }

  onInputClick(isOverlayOpen: boolean = this.isOverlayOpen): void {
    if (this.searchable && isOverlayOpen && !this.isSearching) {
      this.isSearching = true;
      if (document.activeElement !== this.input.nativeElement) {
        this.input.nativeElement.focus();
      }
      if (this.controlDir && this.controlDir.value && this.controlDir.value.name) {
        this.searchPlaceholder = this.controlDir.value.name;
      }
      this.control.setValue(null, { emitEvent: false });
    } else if (this.searchable && !isOverlayOpen && !this.isSearching) {
      if (this.multiSelect) {
        this.control.setValue(this.selectedItems.map(item => item.name).join(', '), {
          emitEvent: false,
        });
      } else {
        this.control.setValue(this.controlDir?.value?.name ? this.controlDir.value.name : null, {
          emitEvent: false,
        });
      }
    }
  }

  isTextOverflow(element: HTMLElement): boolean {
    return element.children[0].children[0]?.clientWidth > element.clientWidth;
  }

  private calculateActiveIndex(): void {
    if (this.selectedItems.length && this.items) {
      const selectedItem = this.selectedItems[0];
      this.activeItemIndex = this.items.findIndex(item => item.name === selectedItem.name);
    } else {
      this.activeItemIndex = null;
    }
  }
}
