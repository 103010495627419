import { Action } from '@ngrx/store';
import { NgrxMetaActions } from './ngrx-meta.actions';

export interface StoreResetOptions {
  action?: Action<any>;
}

const defaultOptions: StoreResetOptions = {
  action: NgrxMetaActions.resetStore,
};

export function storeReset(options: StoreResetOptions) {
  const { action: actionType } = Object.assign({}, defaultOptions, options || {});
  return reducer => (state: any, action: Action<any>) => {
    if (action.type === actionType.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
