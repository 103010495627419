import { Pipe, PipeTransform } from '@angular/core';
import { AidGroup, PlatformRoamingType } from '@manage/access-control/data/access-control.model';
import { ChargePointAid } from '@manage/access-control/data/filter.model';
import { TranslateService } from '@ngx-translate/core';
import { GroupAssignment } from '@operate/charging-infrastructure-detail/model/charging-infrastructure-detail.model';

@Pipe({
  name: 'aidName',
})
export class AidNamePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(group: AidGroup | ChargePointAid | GroupAssignment): string {
    if (!group) return null;

    let typeWithGroupName = group as GroupAssignment;
    let typeWithName = group as AidGroup;

    const isSystemRoaming =
      this.isSystemGroup(group) &&
      (group as ChargePointAid | AidGroup).platformRoamingType !== PlatformRoamingType.NONE;
    const isSystemRoamingInactive =
      this.isSystemGroup(group) &&
      (group as ChargePointAid | AidGroup).platformRoamingType === PlatformRoamingType.NONE;
    return isSystemRoaming
      ? this.translate.instant('ACCESS_CONTROL.ROAMING_ACTIVE')
      : isSystemRoamingInactive
        ? this.translate.instant('ACCESS_CONTROL.ROAMING_INACTIVE')
        : typeWithGroupName.groupName
          ? typeWithGroupName.groupName
          : typeWithName.name;
  }

  private isSystemGroup(group: AidGroup | ChargePointAid | GroupAssignment): boolean {
    return !group.userOwned;
  }
}
