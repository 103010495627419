<h3 class="headline">{{ data.objectToDeleteName }}</h3>
<div class="message">
  <p *ngIf="data.message">{{ data.message }}</p>
  <p *ngIf="!data.message">{{ 'GLOBAL.CONFIRM_DELETE_WITH_OBJECT' | translate: data }}</p>
</div>
<hr />
<div class="button-panel">
  <button
    [attr.data-test]="'cancel-button'"
    id="cancelButton"
    eopButton
    [color]="ButtonColor.TERTIARY"
    (click)="onCancel()"
  >
    {{ 'GLOBAL.CANCEL' | translate }}
  </button>
  <button [attr.data-test]="'confirm-button'" id="deleteButton" eopButton (click)="onDelete()">
    {{ 'GLOBAL.DELETE' | translate }}
  </button>
</div>
