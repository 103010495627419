export interface Link {
  active: boolean;
  id: string;
  name: string;
  type: string;
  subLinks: Link[];
}

export interface FaqElement {
  id: string;
  type: string;
  title: string;
  questions: Question[];
  isExpandable: boolean;
}

export interface Question {
  question: string;
  answer: string;
  pagesId: string[];
}

export interface ExtendedQuestion extends Question {
  faqId: string;
  index: number;
}

export enum PageType {
  USER_MANUAL = 'manual',
  FAQ = 'faq',
  CHAT = 'chat',
}

export enum ClassSelectors {
  WIKI_MARKDOWN_H1 = 'wiki-markdown-h1',
  WIKI_MARKDOWN_H2 = 'wiki-markdown-h2',
}
