import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from '@widgets/modal/modal.module';
import { WidgetModule } from '@widgets/widgets.module';
import { FaqComponent } from './components/faq/faq.component';
import { TableOfContentComponent } from './components/table-of-content/table-of-content.component';
import { UserManualComponent } from './components/user-manual/user-manual.component';
import { WikiComponent } from './components/wiki.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ChatComponent } from '@content/wiki/components/chat/chat.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatExpansionModule,
    WidgetModule,
    MatTooltipModule,
    ModalModule.forChild(),
  ],
  declarations: [
    WikiComponent,
    TableOfContentComponent,
    UserManualComponent,
    FaqComponent,
    ChatComponent,
    SafeHtmlPipe,
  ],
  exports: [],
  providers: [SafeHtmlPipe],
})
export class WikiModule {}
