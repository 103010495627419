<div
  class="file-component background-{{ backgroundColor }} {{
    allowDownload ? 'cursor-pointer' : ''
  }}"
  (click)="onFileClick()"
>
  <div class="file-icon-wrapper">
    <eop-icon
      *ngIf="!loading"
      class="file-icon"
      [identifier]="isValidImageFormat(file) ? 'image-outlined' : 'document-download-outlined'"
      [vaylensFont]="true"
      [size]="IconSize.NORMAL"
    ></eop-icon>
    <spinner [spinnerSize]="25" *ngIf="loading"></spinner>
  </div>
  <div class="file-name" matTooltip="{{ file.name }}" [matTooltipShowDelay]="300">
    {{ file.name }}
  </div>
  <div *ngIf="!hideRemoveIcon" class="remove-file-button" (click)="removeFile(file)">
    <eop-icon
      class="remove-icon"
      identifier="close-circle-filled"
      [vaylensFont]="true"
      [size]="IconSize.NORMAL"
    ></eop-icon>
  </div>
</div>
