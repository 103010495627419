import { Highlightable } from '@angular/cdk/a11y';
import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DropdownItem } from '../dropdown-type';

/**
 * @internal
 *
 * Currently this wraps the templateRef passed to the dropdown component.
 * For now there is no plan to expose this component to the public api.
 */
@Component({
  selector: 'emob-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
})
export class OptionComponent implements OnInit, OnDestroy, Highlightable {
  @Input()
  public set item(value: DropdownItem) {
    const item = { ...value };
    this._item = item;
    this.itemSub$.next(item);
  }

  public get item(): DropdownItem {
    return this._item;
  }

  private _item: DropdownItem = { name: '', value: '' };
  private readonly itemSub$ = new BehaviorSubject<DropdownItem>({ name: '', value: '' });

  @Input()
  public set index(value: number) {
    this._index = value;
    this.indexSub$.next(value);
  }

  public get index(): number {
    return this._index;
  }

  private _index = 0;
  private readonly indexSub$ = new BehaviorSubject<number>(0);

  click$ = new Observable<{ item: DropdownItem; index: number }>();
  disabled?: boolean;

  private readonly unsubscribe$ = new Subject<void>();

  public isActive = false;
  public isNoResult = false;

  constructor(public host: ElementRef) {}

  ngOnInit(): void {
    this.isNoResult = this.host.nativeElement.className.includes('no-result');

    this.click$ = fromEvent<MouseEvent>(this.element, 'mousedown').pipe(
      switchMap((ev: MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        return fromEvent(this.element, 'click');
      }),
      switchMap(() =>
        combineLatest([this.itemSub$.asObservable(), this.indexSub$.asObservable()]).pipe(
          map(([item, index]: [DropdownItem, number]) => ({ item, index })),
          take(1)
        )
      ),
      takeUntil(this.unsubscribe$)
    );
  }

  get element(): HTMLElement {
    return this.host.nativeElement;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setActiveStyles(): void {
    this.isActive = true;
  }

  setInactiveStyles(): void {
    this.isActive = false;
  }
}
