<div class="text-with-title">
  <p class="title">{{ title }}</p>
  <div class="wrapper">
    <eop-icon
      *ngIf="iconIdentifier && tooltip"
      [color]="IconColor.DEFAULT"
      [size]="IconSize.MINI"
      [vaylensFont]="true"
      [identifier]="iconIdentifier"
      [tooltip]="tooltip"
    >
    </eop-icon>
    <span class="content">{{ text }}</span>
  </div>
  <hr />
</div>
