import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Time } from '@pay/pricing-charging-points/data/pricing-charging-points.data';
import { DropdownItem } from '@widgets/dropdown/dropdown-type';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'eop-time-form',
  templateUrl: './time-form.component.html',
  styleUrls: ['./time-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeFormComponent),
      multi: true,
    },
  ],
})
export class TimeFormComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('minuteInput') minuteInput: ElementRef<HTMLInputElement>;
  private readonly unsubscribe$ = new Subject<void>();
  @Input() userTimeFormat: string | 'HH.mm' | 'hh:mm a' = 'HH.mm';
  @Input() error: boolean;

  hour: string;
  minute: string;
  disabled: boolean = false;

  amPmDropdownItems: DropdownItem[] = [
    {
      value: 'AM',
      name: 'AM',
    },
    {
      value: 'PM',
      name: 'PM',
    },
  ];
  amPmForm = new FormGroup({
    amPm: new FormControl(this.amPmDropdownItems[0]),
  });

  private onChangeCallback: (value: Time) => void = () => {};
  private onTouchedCallback: () => void = () => {};

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.amPmForm.controls.amPm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(amPm => {
      if (this.userTimeFormat === 'hh:mm a') {
        this.updateValue();
        if (this.hour === '00') {
          this.hour = '12';
        }
        if (this.hour === '12' && amPm.value === 'PM') {
          this.minute = '00';
          this.minuteInput.nativeElement.value = '00';
        }
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateValue() {
    this.onTouchedCallback();

    let hour = this.hour ? parseInt(this.hour, 10) : null;
    if (this.userTimeFormat === 'hh:mm a' && hour !== null) {
      const amPm = this.amPmForm.controls.amPm.value?.value;
      if (amPm === 'PM' && hour <= 12) {
        // Convert PM hours to 24-hour format
        hour += 12;
        if (hour === 24) {
          hour = 0; // 24-hour format hasn't 24:00
        }
      }
    }

    this.onChangeCallback({
      minute: this.minute,
      hour: hour !== null ? hour.toString().padStart(2, '0') : null,
    });
  }

  onHourChange(event: Event) {
    let input = (event.target as HTMLInputElement).value;

    if (input.length > 2) {
      input = input.slice(0, 2);
    }

    const value = parseInt(input, 10);
    if (this.userTimeFormat === 'HH:mm') {
      if (!isNaN(value) && value >= 0 && value < 24) {
        this.hour = input;
      } else if (!value) {
        this.hour = null;
      } else {
        this.hour = '00'; // Reset to "00" if invalid
      }
    } else {
      if (!isNaN(value) && value >= 0 && value <= 12) {
        this.hour = input;
        if (input === '12') {
          this.minute = '00';
          this.minuteInput.nativeElement.value = '00';
        }
      } else if (!value) {
        this.hour = null;
      } else {
        this.hour = '12'; // Reset to "12" if invalid
      }
    }

    (event.target as HTMLInputElement).value = this.hour;
    this.updateValue();
  }

  onMinuteChange(event: Event) {
    let input = (event.target as HTMLInputElement).value;

    if (input.length > 2) {
      input = input.slice(0, 2);
    }

    const value = parseInt(input, 10);
    if (!isNaN(value) && value >= 0 && value <= 59) {
      if (
        this.userTimeFormat === 'hh:mm a' &&
        this.hour === '12' &&
        this.amPmForm.controls.amPm.value?.value === 'PM'
      ) {
        this.minute = '00';
      } else {
        this.minute = input;
      }
    } else if (!value) {
      this.minute = null;
    } else {
      this.minute = '00'; // Reset to "00" if invalid
    }
    (event.target as HTMLInputElement).value = this.minute;
    this.updateValue();
  }

  onHourBlur(event: Event) {
    let input = (event.target as HTMLInputElement).value;
    const value = parseInt(input, 10);

    if (this.userTimeFormat === 'HH:mm') {
      if (!isNaN(value) && value >= 0 && value < 24) {
        this.hour = value.toString().padStart(2, '0');
      } else {
        this.hour = '00';
      }
    } else {
      if (!isNaN(value) && value >= 0 && value <= 12) {
        this.hour = value.toString().padStart(2, '0');
        if (this.hour === '00') {
          this.hour = '12';
        }
        if (this.hour === '12' && this.amPmForm.controls.amPm.value?.value === 'PM') {
          this.minute = '00';
          this.minuteInput.nativeElement.value = '00';
        }
      } else {
        this.hour = '12'; // Default to 12 if invalid
      }
    }

    (event.target as HTMLInputElement).value = this.hour;
    this.updateValue();
  }

  onMinuteBlur(event: Event) {
    let input = (event.target as HTMLInputElement).value;
    const value = parseInt(input, 10);

    if (
      !isNaN(value) &&
      value >= 0 &&
      value <= 59 &&
      !(this.hour === '12' && this.amPmForm.controls.amPm.value?.value === 'PM')
    ) {
      this.minute = value.toString().padStart(2, '0');
    } else {
      this.minute = '00';
    }

    (event.target as HTMLInputElement).value = this.minute;
    this.updateValue();
  }

  registerOnChange(fn: (value: Time) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.amPmForm.controls.amPm.disable() : this.amPmForm.controls.amPm.enable();
    this.changeDetectorRef.detectChanges();
  }

  writeValue(value: Time): void {
    if (value) {
      let hour = parseInt(value.hour, 10);
      this.minute = value.minute;

      if (this.userTimeFormat === 'hh:mm a') {
        const isPM = hour >= 12;
        this.amPmForm.controls.amPm.setValue(this.amPmDropdownItems[isPM ? 1 : 0]); // PM for 1, AM for 0

        // Convert 24-hour format to 12-hour format
        if (isPM) {
          hour = hour % 12 || 12;
        } else {
          hour = hour % 12 || 0;
        }
      }

      this.hour = hour.toString().padStart(2, '0');
    }
    this.changeDetectorRef.detectChanges();
  }
}
