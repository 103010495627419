import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { ChipColor } from './chipColor';

@Component({
  selector: 'app-innogy-chips',
  templateUrl: './innogy-chips.component.html',
  styleUrls: ['./innogy-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnogyChipsComponent implements OnChanges {
  @Input()
  chipsRemovable = false;
  @Input()
  showChipsHeadline = false;
  @Input()
  chipListCollapsable = false;
  @Input()
  customChipsHeadline = 'Tags';
  @Input()
  inputEnabled = false;
  @Input()
  chipsData = [];
  @Input()
  chipKey = 'value';
  @Input()
  chipName: string;
  @Input()
  chipColor = ChipColor.DEFAULT;
  @Input()
  placeholderText = '';
  @Input()
  emphasized: boolean;
  @Input()
  maxChipTextLength: number = 0;
  @Input()
  infoText: string = '';
  @Input()
  infoIconOutsideChip = true;
  @Input()
  shouldChipTextCrop = false;
  @Input()
  margin = true;
  @Output()
  chipRemoved = new EventEmitter<any>();

  cssColorStyle: string;
  chipsExpanded: boolean;
  expanderRequired: boolean;
  matChipInputAddOnBlur = true;
  showInput = false;

  collapseLimit = 50;

  @ViewChild('chipsContainer', { static: true })
  private chipsContainer: ElementRef<HTMLElement>;

  constructor(
    public cdr: ChangeDetectorRef,
    public translate: TranslateService
  ) {}

  protected readonly IconColorEnum = IconColor;

  protected readonly IconWeightEnum = IconWeight;

  protected readonly IconSizeEnum = IconSize;

  removeChip(chip) {
    this.chipsData = this.chipsData.filter(obj => {
      return obj !== chip;
    });
    this.chipRemoved.emit(chip);
    if (!this.cdr['destroyed']) this.cdr.detectChanges();
  }

  checkIfListIsOverflowing(): void {
    const element = this.chipsContainer.nativeElement;
    const listIsOverflowing = element.scrollHeight > element.clientHeight;

    if (!listIsOverflowing) {
      this.chipsExpanded = false;
    }
  }

  expandChipList() {
    this.chipsExpanded = true;
  }

  collapseChipList() {
    this.chipsExpanded = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => this.checkIfListIsOverflowing());
  }
}
