import { WeekDay } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export interface WeekDayPickerOptions {
  weekStart?: WeekDay;
  disabledList?: WeekDay[];
  selected?: WeekDay[] | WeekDay;
  activeColor?: string;
  activeBgColor?: string;
  activeBorderColor?: string;
  inactiveColor?: string;
  inactiveBgColor?: string;
  inactiveBorderColor?: string;
  isMulti?: boolean;
}

export interface WeekDayItem {
  day: WeekDay;
  name: string;
  isSelected: boolean;
  isDisabled: boolean;
}

@Component({
  selector: 'eop-week-day-picker',
  templateUrl: './week-day-picker.component.html',
  styleUrls: ['./week-day-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekDayPickerComponent implements OnChanges, OnInit {
  private readonly initOptions: WeekDayPickerOptions = {
    weekStart: WeekDay.Monday,
    selected: [],
    disabledList: [],
    isMulti: true,
  };

  days: WeekDayItem[] = [
    {
      day: WeekDay.Monday,
      name: this.translate.instant('GLOBAL.MONDAY').substring(0, 3),
      isSelected: false,
      isDisabled: false,
    },
    {
      day: WeekDay.Tuesday,
      name: this.translate.instant('GLOBAL.TUESDAY').substring(0, 3),
      isSelected: false,
      isDisabled: false,
    },
    {
      day: WeekDay.Wednesday,
      name: this.translate.instant('GLOBAL.WEDNESDAY').substring(0, 3),
      isSelected: false,
      isDisabled: false,
    },
    {
      day: WeekDay.Thursday,
      name: this.translate.instant('GLOBAL.THURSDAY').substring(0, 3),
      isSelected: false,
      isDisabled: false,
    },
    {
      day: WeekDay.Friday,
      name: this.translate.instant('GLOBAL.FRIDAY').substring(0, 3),
      isSelected: false,
      isDisabled: false,
    },
    {
      day: WeekDay.Saturday,
      name: this.translate.instant('GLOBAL.SATURDAY').substring(0, 3),
      isSelected: false,
      isDisabled: false,
    },
    {
      day: WeekDay.Sunday,
      name: this.translate.instant('GLOBAL.SUNDAY').substring(0, 3),
      isSelected: false,
      isDisabled: false,
    },
  ];
  value: WeekDay[] | WeekDay = null;

  @Input() options: WeekDayPickerOptions = this.initOptions;
  @Input() readonly = false;
  @Output() selectionChanged = new EventEmitter<WeekDay | WeekDay[]>();

  private readonly basicOptions: WeekDayPickerOptions = {
    inactiveColor: '#4E4E5F',
    inactiveBgColor: '#FFF',
    inactiveBorderColor: 'transparent',
    activeBorderColor: 'transparent',
    activeBgColor: '#475EFF',
    activeColor: '#F5F6FF',
  };
  private readonly readonlyOptions: WeekDayPickerOptions = {
    inactiveColor: '#606076',
    inactiveBgColor: '#F1F1FA',
    inactiveBorderColor: 'transparent',
    activeBorderColor: 'transparent',
    activeBgColor: '#E0E4FF',
    activeColor: '#475EFF',
  };

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    // init weekStart
    const index = this.days.map(x => x.day).indexOf(this.options.weekStart);
    for (let i = 0; i < index; i++) {
      this.days.push(this.days.shift() as any);
    }

    // init disabled
    this.days
      .filter(x => this.options.disabledList.includes(x.day))
      .forEach(x => (x.isDisabled = true));

    // init selected
    if (Array.isArray(this.options.selected)) {
      this.days
        .filter(x => (this.options.selected as WeekDay[]).includes(x.day))
        .forEach(x => (x.isSelected = true));
    } else {
      this.days.filter(x => this.options.selected === x.day).forEach(x => (x.isSelected = true));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.options?.currentValue) {
      this.options = {
        ...this.initOptions,
        ...this.basicOptions,
        ...changes.options.currentValue,
      };
    }

    if (this.days) {
      this.days = this.days.map(d => {
        return {
          ...d,
          isDisabled:
            changes.options.currentValue.disabledList.filter((day: WeekDay) => day === d.day)
              .length > 0,
        };
      });
    }

    if (changes?.readonly?.currentValue && changes?.options?.currentValue) {
      this.options = {
        ...this.initOptions,
        ...this.readonlyOptions,
        ...changes.options.currentValue,
      };
    }
  }

  dayClicked(d: WeekDayItem) {
    if (d.isDisabled) {
      return;
    }
    if (!d.isSelected && !this.options.isMulti) {
      this.days.forEach(x => (x.isSelected = false));
    }
    d.isSelected = !d.isSelected;
    if (this.options.isMulti) {
      this.value = this.days.filter(x => x.isSelected).map(x => x.day);
    } else {
      const t = this.days.find(x => x.isSelected);
      this.value = t ? t.day : null;
    }
    this.selectionChanged.emit(this.value);
  }
}
