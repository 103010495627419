import { GoogleAnalyticsCategory } from '@shared/google-analytics/google-analytics.model';
import { IconWeight } from '@widgets/eop-icon';
import { ChipColor } from '@widgets/innogy-chips/chipColor';

export interface ITableSettings {
  height?: string;
  minHeight?: string;
  expandable?: boolean;
  sortable?: boolean;
  customAction?: boolean;
  customActionLabel?: string;
  customActionIcon?: string;
  vaylensFont?: boolean;
  customActionIconWeight?: IconWeight;
  customAction2?: boolean;
  customAction2Label?: string;
  customAction2Icon?: string;
  customAction2IconWeight?: IconWeight;
  checkable?: boolean;
  filterable?: boolean;
  deletable?: boolean;
  notDeletableActionIcon?: string;
  notDeletableActionLabel?: string;
  editable?: boolean;
  clickableRow?: boolean;
  disabledCheckboxTooltip?: string;
  nextDataSelection?: string | 'scroll' | 'paginator';
  searchable?: boolean;
  searchPlaceholder?: string[];
  uniqueKey?: string;
  showChipList?: boolean;
  chipKey?: string;
  chipText?: string;
  shouldChipTextCrop?: boolean;
  chipsHeadline?: string;
  chipColor?: ChipColor;
  pageSizeOptions?: number[];
  initPageSize?: number;
  showCounterHeadline?: boolean;
  customCounterHeadline?: string;
  expansionPadding?: boolean;
  defaultSort?: TableSortSettings;
  maxSelectedItems?: number;
  trackingCategory?: GoogleAnalyticsCategory;
  showEmptyResultsBanner?: boolean;
  testId?: string;
}

export interface TableSortSettings {
  column: string;
  direction: string;
}
