import { Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector } from '@angular/core';
import { SharedRefService } from '@widgets/shared-ref-service/shared-ref-service.service';
import { PopoverContent, PopoverRef } from '../classes/popover-ref';
import { PopoverComponent } from '../containers/popover.component';

export interface OverlayParams<T> {
  origin: ElementRef;
  content: PopoverContent;
  data?: T;
  callback?: T;
  width?: string | number;
  height?: string | number;
  vcr: T;
}

@Injectable()
export class OverlayService {
  get viewPortHeight(): number {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  }

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private sharedReferenceService: SharedRefService
  ) {
    document.body.addEventListener('scroll', event => {
      if (this.sharedReferenceService.activeOverlayRef) {
        this.sharedReferenceService.activeOverlayRef.updatePosition();
      }
    });
  }

  open<T>({ origin, content, data, vcr, callback, width, height }: OverlayParams<T>) {
    const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const isBelowMax = viewPortHeight <= 300;
    const maxHeight: number | string = isBelowMax ? viewPortHeight : 300;

    const overlayRef = this.overlay.create(
      this.getOverlayConfig({
        origin,
        width,
        maxHeight: (height as number) > maxHeight ? maxHeight : height,
      })
    );

    const popoverRef = new PopoverRef<T>(overlayRef, content, data, vcr, callback);
    const injector = this.createInjector(popoverRef, this.injector);

    overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));
    this.sharedReferenceService.storeOverlay(overlayRef);
    return popoverRef;
  }

  closeAllOverlays() {
    this.sharedReferenceService.closeAllOverlays();
  }

  createInjector(popoverRef: PopoverRef, injector: Injector) {
    const tokens = new WeakMap([[PopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getOverlayConfig({ origin, width, maxHeight }): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      width,
      maxHeight: maxHeight ? maxHeight : 'calc(100vh -' + `origin.offsetTop)`,
      backdropClass: 'popover-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      panelClass: 'cdk-popover-panel',
    });
  }

  private getOverlayPosition(origin: ElementRef): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .withLockedPosition()
      .withPush(true)
      .withPositions([
        {
          panelClass: 'custom-panel-place-1',
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          panelClass: 'custom-panel-place-2',
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          panelClass: 'custom-panel-place-3',
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);
  }
}
