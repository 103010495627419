import { IconColor } from '@widgets/eop-icon';
import { ITableConfig, ITableData } from '@widgets/innogy-data-table';

export interface InfoDialogModel extends ConfirmDialogModel {
  title: string;
  bulletPoints?: string[];
  tableConfig: ITableConfig;
  tableData: ITableData;
}

export interface ConfirmDialogWithHintModel {
  headline: string;
  hint: string;
}

export interface ConfirmDialogWithTitleIconTextModel {
  title: string;
  icon: string;
  message: string;
}

export interface ConfirmDialogModel {
  message: string;
  yesnoButtons?: boolean;
  info?: string; // information text inside info box
  headline?: string; // modal headline will replace "Confirmation" text
  titleIconIdentifier?: string;
  titleIconColor?: IconColor;
}

export interface DeleteDialogModel {
  objectToDeleteName: string;
  message?: string;
}
