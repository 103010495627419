import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';

function _window(): any {
  // return the native window obj
  return window;
}

@Injectable()
export class WindowRef {
  get nativeWindow(): any {
    return _window();
  }

  get onResize(): Observable<Window> {
    return this.resizeSubject$.asObservable();
  }

  private resizeSubject$: Subject<Window>;

  constructor(private eventManager: EventManager) {
    this.resizeSubject$ = new Subject();
    window.addEventListener('resize', this.onWindowResize.bind(this));
  }

  private onWindowResize(event: UIEvent) {
    this.resizeSubject$.next(<Window>event.target);
  }
}
