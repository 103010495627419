import { DataTableRow } from '@widgets/innogy-data-table/data/data-table-row.data';

export interface ITableData {
  paging: Paging;
  rows?: DataTableRow[];
  // Compatibility for old data
  data?: any[];
}

export interface Paging {
  currentPage?: number;
  totalPages?: number;
  itemsPerPage?: number;
  totalItems?: number;
  nextPageAvailable?: boolean;
}
