<div
  [ngClass]="{
    'menu-content': true,
    'menu-content-shadow': true,
    'menu-content--collapsed': isMenuCollapsed,
    'menu-content--notificationShowed': notification$ | async,
  }"
>
  <div class="menu-button" (click)="collapseMenu()" [attr.data-test]="'header_menu'">
    <img
      *ngIf="!menuCollapsed"
      class="image"
      src="assets/img/logo/vaylens_full.svg"
      alt="Vaylens Logo"
    />
    <eop-icon
      *ngIf="!menuCollapsed"
      identifier="chevron-left-sq-outlined"
      [clickable]="true"
      [color]="IconColor.INHERIT"
      [vaylensFont]="true"
      class="menu-icon"
    ></eop-icon>
    <img
      *ngIf="menuCollapsed"
      class="image"
      src="assets/img/logo/vaylens_small.svg"
      alt="Vaylens Logo"
    />
  </div>

  <div class="menu-inner-content">
    <mat-accordion displayMode="flat" class="menu--accordion" [multi]="false">
      <eop-menu-group
        [menuPoints]="menuPoints"
        [menuCollapsed]="menuCollapsed"
        [activeGroupName]="activeMenuGroup"
        (activeGroupChange)="onMenuGroupChange($event)"
      ></eop-menu-group>
    </mat-accordion>
    <eop-user-menu
      [menuCollapsed]="menuCollapsed"
      [activeGroupName]="activeMenuGroup"
      (activeGroupChange)="onMenuGroupChange($event)"
    ></eop-user-menu>
    <eop-menu-group
      [menuPoints]="legalMenuPoints"
      [menuCollapsed]="menuCollapsed"
      [activeGroupName]="activeMenuGroup"
      (activeGroupChange)="onMenuGroupChange($event)"
    >
    </eop-menu-group>
    <eop-menu-footer [menuCollapsed]="menuCollapsed"> </eop-menu-footer>
  </div>
</div>

<div
  [ngClass]="{ backdrop: screenWidth < $screenWidthDesktopMax && !isMenuCollapsed }"
  (click)="collapseMenu()"
></div>
