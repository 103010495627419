import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { InnogyChipsLiteComponent } from './innogy-chips-lite.component';
import { EopButtonModule } from '@widgets/eop-button/eop-button.module';

@NgModule({
  declarations: [InnogyChipsLiteComponent],
  imports: [CommonModule, MatTooltipModule, EopIconModule, EopButtonModule],
  exports: [InnogyChipsLiteComponent],
})
export class InnogyChipsLiteModule {}
