import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DateConversionService } from '@shared/services/util';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'userDateTime',
})
export class UserDateTimePipe implements PipeTransform {
  constructor(private dateConversionService: DateConversionService) {}

  public transform(value: string, shouldAdaptUserTimezone = false, showAsDuration = false): string {
    if (!value) return value;
    if (showAsDuration) return this.showAsDuration(value);
    const format = this.dateConversionService.getDateTimeFormatFromUser();
    const timezone = this.dateConversionService.getUserTimeZoneAsString();
    //parse the passed value with timezone in the timestamp
    const momentDate = moment.parseZone(value);

    // If moment didn't understand the value, return it unformatted.
    if (!momentDate.isValid()) {
      return value;
    }

    // Otherwise, return the date formatted as requested.
    return shouldAdaptUserTimezone
      ? this.adaptTimezone(value, format, timezone)
      : momentDate.format(format);
  }

  private adaptTimezone(time: string, format: string, timezoneID: string) {
    const adaptedTime = momentTimeZone(time).isValid()
      ? momentTimeZone(time).tz(timezoneID).format(format)
      : time;
    return adaptedTime;
  }

  private showAsDuration(value: string) {
    return this.dateConversionService.getFormattedDurationFromISO8601TimeInterval(value);
  }
}
