import { Component } from '@angular/core';

@Component({
  selector: 'eop-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  initScriptElement: HTMLScriptElement;
  bootstrapScriptElement: HTMLScriptElement;

  constructor() {
    try {
      // Create a new <script> element
      this.initScriptElement = document.createElement('script');
      this.initScriptElement.src = 'https://api-int.vaylens.com/portal/chatbot';
      document.body.appendChild(this.initScriptElement);

      this.bootstrapScriptElement = document.createElement('script');
      this.bootstrapScriptElement.src =
        'https://emobility--dev.sandbox.my.site.com/ESWaf4v1736943249758/assets/js/bootstrap.min.js';
      this.bootstrapScriptElement.onload = () => {
        if (typeof window['initEmbeddedMessaging'] === 'function') {
          window['initEmbeddedMessaging']();
        } else {
          console.error('initEmbeddedMessaging function not found');
        }
      };
      this.bootstrapScriptElement.defer = true;
      document.body.appendChild(this.bootstrapScriptElement);
    } catch (e) {
      console.log('ChatComponent - error in addScriptToBody: ', e);
    }
  }
}
