<div
  [ngClass]="{ 'gray-background': hasGrayBackground, disabled: showDisabledToggle || disabled }"
  class="toggle-box-wrapper"
>
  <div
    class="toggle-box"
    [ngClass]="{
      'medium-padding': paddingSize == PaddingSize.MEDIUM,
      'bigger-padding': paddingSize == PaddingSize.BIG,
    }"
  >
    <div [ngClass]="{ 'has-description': !!description }" class="label">
      <div class="label-content">
        <div
          [ngClass]="{
            'label-error': titleError,
            'heading-s': titleSize === TitleSize.SMALL,
            'body-l': titleSize === TitleSize.MEDIUM,
            'heading-m': titleSize === TitleSize.BIG,
          }"
        >
          {{ title }}
        </div>
        <eop-icon-with-tooltip
          *ngIf="infoText"
          [iconColor]="IconColor.NORMAL"
          [iconIdentifier]="'info-circle-filled'"
          [infoText]="infoText"
        >
        </eop-icon-with-tooltip>
        <eop-innogy-chips-lite
          *ngIf="titleChip"
          [chipColor]="titleChip.chipColor"
          [ngClass]="{ 'title-chip-right': titleSize !== TitleSize.BIG }"
          [text]="titleChip.text"
          class="title-chip"
        ></eop-innogy-chips-lite>
      </div>
      <div *ngIf="description" class="description body-m">{{ description }}</div>
    </div>
    <div *ngIf="editMode" class="toggle-switch">
      <div *ngIf="!showDisabledToggle" [matTooltip]="toggleTooltip">
        <ng-content select="[switch]"></ng-content>
      </div>
      <div
        *ngIf="showDisabledToggle"
        [forceEvalStyles]="true"
        [matTooltip]="toggleTooltip"
        class="disabled-toggle-icon"
        inlineSVG="assets/img/a-toggle-switch-disabled.svg"
      ></div>
    </div>
  </div>
  <hr *ngIf="(hasContent && activated) || (hasContent && !hideContentLine)" />
  <div class="content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
