import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationLevel } from '@shared/services/notification';
import { IconSize, IconWeight } from '@widgets/eop-icon';
import { SnackbarService } from '@widgets/snackbar/snackbar.service';

export interface IFile {
  mime: string;
  data: string;
  size?: number;
}

export interface FileLoadedEvent {
  fileName: string;
  hasErrors: boolean;
}

@Component({
  selector: 'eop-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true,
    },
  ],
})
export class FileUploadComponent implements ControlValueAccessor {
  @Input() width: number;
  @Input() height: number;
  @Input() fileType: string;
  @Input() acceptedMine: string = null;
  @Input() mineErrorMessage: string;
  @Input() disabled: boolean;
  @Input() maxFileSize: number = null;
  @Output() fileLoaded: EventEmitter<FileLoadedEvent> = new EventEmitter<FileLoadedEvent>();
  loading = false;
  private objectValue: any;

  private onChangeCallback: (value: IFile) => void = () => {};
  private onTouchedCallback: () => void = () => {};

  readonly IconWeight = IconWeight;
  readonly IconSize = IconSize;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {}

  registerOnChange(fn: (value: IFile) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    this.objectValue = obj;
  }

  fileInputClick(event: HTMLInputElement) {
    event.value = null;
  }

  onSelectFile(file: File): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (file) {
        this.loading = true;
        const fileToProccess: IFile = {
          mime: null,
          data: null,
          size: 0,
        };
        fileToProccess.size = file.size;
        const validSize = this.isFileSizeValid(fileToProccess);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (eventReader: ProgressEvent) => {
          const fileSplit = ((eventReader.target as FileReader).result as string).split(',');
          fileToProccess.data = fileSplit[1];
          fileToProccess.mime = fileSplit[0].split(';')[0].split(':')[1];
          this.onChangeCallback(fileToProccess);
          const validType = this.isFileTypeValid(fileToProccess);
          this.loading = false;
          this.fileLoaded.emit({
            fileName: file.name,
            hasErrors: !(validSize && validType),
          });
          resolve(true);
        };
      }
    });
  }

  private isFileSizeValid(fileToProccess: IFile): boolean {
    if (this.maxFileSize !== null && fileToProccess.size > this.maxFileSize * 1000000) {
      this.snackbar.openSnackbar(
        this.translate.instant('GLOBAL.MAX_FILE_SIZE_IS') + ` ${this.maxFileSize} MB`,
        NotificationLevel.ERROR,
        8000
      );
      this.loading = false;
      return false;
    }
    return true;
  }

  private isFileTypeValid(fileToProccess: IFile): boolean {
    if (this.acceptedMine !== null && this.acceptedMine !== fileToProccess.mime) {
      this.snackbar.openSnackbar(this.mineErrorMessage, NotificationLevel.ERROR, 8000);
      this.loading = false;
      return false;
    }
    return true;
  }
}
