<div class="container {{ 'state-' + state }}">
  <div class="icon-with-text">
    <eop-icon
      [identifier]="iconName"
      [vaylensFont]="true"
      [color]="getIconColor()"
      [size]="IconSize.MONSTER"
    ></eop-icon>
    <div class="messages">
      <div class="heading-m">
        {{ headline }}
      </div>
      <div class="body-l">
        {{ message }}
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</div>
