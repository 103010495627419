import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { EPermissions } from '@auth/login';
import { EDetailedStatusEnum, EStatusEnum, PermissionService } from '@shared/services/util';
import { PoiChargingStation } from '@shared/services/util/map.service';
import { IconColor, IconSize, IconWeight } from '@widgets/eop-icon';
import { POI } from '@widgets/map/data/map.model';

@Component({
  selector: 'app-marker-popup',
  templateUrl: './marker-popup.component.html',
  styleUrls: ['./marker-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkerPopupComponent implements OnInit {
  @Input()
  headline: string;
  @Input()
  content: string[];
  @Input()
  point: POI;
  @Input()
  cropText = true;

  poiChargingStation: PoiChargingStation;
  EStatus = EStatusEnum;
  EDetailedStatus = EDetailedStatusEnum;
  hasViewDetailPermission: boolean;

  readonly IconWeight = IconWeight;
  readonly IconColor = IconColor;
  readonly IconSize = IconSize;

  constructor(
    private cdRef: ChangeDetectorRef,
    private permissionService: PermissionService
  ) {}

  ngOnInit() {
    this.cdRef.detectChanges();
    this.hasViewDetailPermission = this.permissionService.userHasPermission(
      EPermissions.VIEW_CHARGINGSTATION_DETAILS
    );
  }
}
