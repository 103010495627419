<form [formGroup]="form">
  <div class="input-container">
    <input
      formControlName="value"
      [ngClass]="{
        'error-input': !!errorMessage,
        'disabled-input': isDisabled,
        'short-padding': isDisabled || !!errorMessage,
      }"
      type="text"
      [attr.data-test]="testId"
      [placeholder]="placeholder"
      (keyup.enter)="valueControl.valid && onSave()"
      (keyup.esc)="onDiscard()"
    />
    <eop-icon
      *ngIf="isDisabled"
      class="edit-icon"
      [identifier]="'edit-2-outlined'"
      [clickable]="true"
      [vaylensFont]="true"
      [size]="IconSize.SMALL"
      [color]="IconColor.NORMAL"
      [dataTestId]="'edit-' + testId"
      (click)="onEnableEdit()"
    ></eop-icon>
    <div class="mini-buttons">
      <eop-mini-button
        *ngIf="!isDisabled && valueControl.valid"
        [testId]="'save-' + testId"
        [iconName]="'pin-operative'"
        [color]="IconColor.SUCCESS"
        (click)="onSave()"
      >
      </eop-mini-button>
      <eop-mini-button
        *ngIf="!isDisabled"
        [testId]="'discard-' + testId"
        [iconName]="'pin-unknown-error'"
        [color]="IconColor.NAVIGATIONAL"
        (click)="onDiscard()"
      >
      </eop-mini-button>
    </div>
  </div>

  <div class="message" *ngIf="errorMessage">
    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</form>
