import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

export interface Icon {
  identifier: string;
  weight?: IconWeight;
  size?: IconSize;
  color?: IconColor;
  clickable?: boolean;
  dataTestId?: string;
  tooltip?: string;
  vaylensFont?: boolean;
}

export enum IconWeight {
  LIGHT = 'light',
  BOLD = 'bold',
  REGULAR = 'regular',
}

export enum IconColor {
  NORMAL = 'normal',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  DISABLED = 'disabled',
  CLICKABLE = 'clickable',
  LIGHT = 'light',
  NAVIGATIONAL = 'navigational',
  INHERIT = 'inherit',
  WHITE = 'white',
  HIGHLIGHTED = 'highlighted',
  TRANSPARENT = 'transparent',
  DEFAULT = 'default',
}

export enum IconSize {
  MICRO = 'micro',
  MINI = 'mini',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGER = 'larger',
  LARGE = 'large',
  LITTLE_MONSTER = 'little-monster',
  MONSTER = 'monster',
}

@Component({
  selector: 'eop-icon',
  templateUrl: './eop-icon.component.html',
  styleUrls: ['./eop-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EopIconComponent implements OnChanges {
  @Input()
  identifier: string;

  @Input()
  color: IconColor;

  /** @deprecated */
  @Input()
  weight: IconWeight = IconWeight.REGULAR;

  @Input()
  size: IconSize = IconSize.NORMAL;

  @Input()
  clickable = false;

  @Input()
  dataTestId: string = null;

  @Input()
  tooltip: string;

  @Input()
  tooltipDelay: number;

  @Input()
  tooltipPosition: TooltipPosition = 'below';

  @Input()
  matTooltipClass: string = '';

  @Input()
  vaylensFont: boolean;

  iconClasses: string;

  protected readonly IconSize = IconSize;

  ngOnChanges() {
    if (!this.weight) {
      this.weight = IconWeight.REGULAR;
    }
    if (!this.size) {
      this.size = IconSize.NORMAL;
    }
    if (this.identifier && this.identifier !== '') {
      // Set the icon to display
      this.iconClasses = this.vaylensFont
        ? 'vaylens-icon-' + this.identifier
        : 'icon-' + this.weight + '-' + this.identifier;

      // Set the size - NORMAL = default size
      if (this.size !== IconSize.NORMAL) {
        this.iconClasses = `${this.iconClasses} icon-${this.size}`;
      }

      // Set clickable - cursor pointer and secondary Color - (close = black color - should this be done here?)
      if (this.clickable) {
        this.identifier === 'close'
          ? (this.iconClasses = `${this.iconClasses} icon-close-clickable`)
          : (this.iconClasses = `${this.iconClasses} icon-clickable`);
      }

      if (this.color) {
        // Set color
        this.iconClasses = `${this.iconClasses} icon-${this.color}`;
      }
    } else {
      // Clear Icon
      this.iconClasses = '';
    }
  }
}
