<mat-button-toggle-group
  appearance="legacy"
  name="buttonToggleGroup"
  aria-label="button group"
  (change)="onGroupChange($event)"
  [value]="selectedValue"
  [class]="useBlueButtons ? 'blue' : ''"
>
  <mat-button-toggle
    *ngFor="let button of buttonGroup"
    [value]="button.value"
    [disabled]="button.disabled"
    matRipple
    [matRippleColor]="useBlueButtons ? '#475EFF' : '#f1f1fa'"
    [class]="useBlueButtons ? 'body-bold-l blue' : 'body-bold-l'"
  >
    <eop-icon
      [class]="useBlueButtons ? 'icon blue' : 'icon'"
      *ngIf="button.icon"
      [ngClass]="[
        selectedValue === button.value ? 'selected' : '',
        button.disabled ? 'disabled' : '',
      ]"
      [identifier]="button.icon"
      [weight]="button.iconWeight"
      [size]="IconSize.NORMAL"
      [matTooltip]="button.tooltip"
    ></eop-icon>
    <span class="button-text" *ngIf="button.text"> {{ button.text }} </span>
  </mat-button-toggle>
</mat-button-toggle-group>
