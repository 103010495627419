<div class="toggle-button-wrapper">
  <button
    class="btn-toggle"
    [class.active]="leftActive"
    [class.inactive]="!leftActive"
    [class.disabled]="disabled"
    (click)="onLeftClick()"
    [matTooltip]="leftTooltip"
  >
    <eop-icon
      *ngIf="leftIcon"
      [ngClass]="rightText ? 'icon-with-margin' : ''"
      [identifier]="leftIcon"
      [color]="
        disabled
          ? leftActive
            ? IconColor.WHITE
            : IconColor.DISABLED
          : leftActive
            ? IconColor.WHITE
            : IconColor.CLICKABLE
      "
    ></eop-icon>
    {{ leftText }}
  </button>
  <button
    class="btn-toggle"
    [class.active]="rightActive"
    [class.inactive]="!rightActive"
    [class.disabled]="disabled"
    (click)="onRightClick()"
    [matTooltip]="rightTooltip"
  >
    <eop-icon
      *ngIf="rightIcon"
      [ngClass]="rightText ? 'icon-with-margin' : ''"
      [identifier]="rightIcon"
      [color]="
        disabled
          ? rightActive
            ? IconColor.WHITE
            : IconColor.DISABLED
          : rightActive
            ? IconColor.WHITE
            : IconColor.CLICKABLE
      "
    ></eop-icon>
    {{ rightText }}
  </button>
</div>
