import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconColor, IconSize } from '@widgets/eop-icon';
import { ChipLiteColor, ChipLiteSize } from './data/innogy.chips-lite.data';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';

@Component({
  selector: 'eop-innogy-chips-lite',
  templateUrl: './innogy-chips-lite.component.html',
  styleUrls: ['./innogy-chips-lite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnogyChipsLiteComponent {
  @Input() text: string;
  @Input() chipColor: ChipLiteColor;
  @Input() chipSize: ChipLiteSize = ChipLiteSize.SMALL;
  @Input() emphasized: boolean;
  @Input() title: string = '';
  @Input() testId: string;
  @Input() enableTitleTooltip: boolean;
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input() maxWidth: string;
  @Input() iconColor: IconColor;
  @Input() buttonText: string;
  @Input() buttonColor: ButtonColor;

  @Output() leftIconClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() rightIconClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() buttonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected readonly ChipLiteSize = ChipLiteSize;
  protected readonly IconColor = IconColor;
  protected readonly IconSize = IconSize;
  protected readonly ChipLiteColor = ChipLiteColor;

  onLeftIconClick(): void {
    this.leftIconClicked.emit();
  }

  onRightIconClick(): void {
    this.rightIconClicked.emit();
  }

  onButtonClick(): void {
    this.buttonClicked.emit();
  }
}
