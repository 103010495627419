<mat-toolbar>
  @if (inProgress) {
    <spinner [spinnerSize]="42"></spinner>
  } @else {
    <button
      eopButton
      [matMenuTriggerFor]="menu"
      (click)="toggleDropdown()"
      [rightIcon]="dropdownIsOpen ? 'small-chevron-up-filled' : 'small-chevron-down-filled'"
      [disabled]="disabled"
      [attr.data-test]="dataTestId"
    >
      {{ selectedOption || placeholder }}
    </button>
  }
</mat-toolbar>

<mat-menu
  #menu="matMenu"
  (closed)="toggleDropdown()"
  yPosition="above"
  class="mat-menu-panel-buttons"
>
  @for (option of options; track option.value; let i = $index) {
    <button mat-menu-item (click)="onOptionSelect(option)" [attr.data-test]="dataTestId + '-' + i">
      <span class="option-label">{{ option.label }}</span>
      @if (option.description) {
        <div class="option-description">{{ option.description }}</div>
      }
    </button>
  }
</mat-menu>
