<!-- Simple Icon -->
<div
  *ngIf="icon && !vaylensButton"
  class="icon"
  [ngClass]="{
    'is-highlighted': isHighlighted,
    disabled: disabled,
    'inverted-colours': invertColours,
  }"
  [attr.data-test]="dataTestId"
  (click)="disabled || actionClicked.emit($event)"
>
  <eop-icon
    [identifier]="icon"
    [vaylensFont]="vaylensFontIcon"
    [weight]="!vaylensFontIcon ? IconWeight.REGULAR : undefined"
    [clickable]="!disabled"
    [tooltip]="label ? label : undefined"
    [color]="
      disabled ? IconColor.DISABLED : isNavigational ? IconColor.NAVIGATIONAL : IconColor.CLICKABLE
    "
    [dataTestId]="dataTestId + '-icon'"
  ></eop-icon>
</div>

<!-- New vaylens design -->
<div *ngIf="(icon && vaylensButton) || vaylensTextButton">
  <button
    eopButton
    [color]="vaylensButtonColor"
    [iconColor]="vaylensIconColor"
    [iconButton]="vaylensIconButton"
    [skipMinWidth]="true"
    [disabled]="disabled"
    [leftIcon]="icon"
    [attr.data-test]="dataTestId"
    [tooltip]="buttonTooltip"
    [condensed]="condensedButton"
    [compact]="condensedButton"
    (click)="disabled || actionClicked.emit($event)"
  >
    {{ label }}
  </button>
</div>
