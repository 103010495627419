<div class="notifications-wrapper">
  <div (click)="toggleMessages()" type="button" class="button-with-label">
    <eop-icon
      [clickable]="true"
      [color]="IconColor.INHERIT"
      [size]="IconSize.NORMAL"
      [vaylensFont]="true"
      identifier="eoperate-ic-notifications"
    ></eop-icon>
  </div>
  <div class="dot-container">
    <span *ngIf="hasNewMessages$ | async" class="dot"></span>
  </div>
  <eop-login-notification
    [activeMessages]="activeMessages$ | async"
    [userLanguage]="userLanguage$ | async"
    (markNotificationsSeen)="markNotificationsSeen()"
  ></eop-login-notification>
</div>
