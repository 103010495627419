import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSize } from '@widgets/eop-icon';
import { FileData } from '@widgets/multiple-file-upload/multiple-file-upload.component';

export enum FileBackgroudColor {
  'WHITE' = 'white',
  'GRAY' = 'gray',
}

@Component({
  selector: 'eop-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileComponent {
  @Input({ required: true }) file: FileData;
  @Input() allowDownload: boolean;
  @Output() onRemoveFile = new EventEmitter<FileData>();
  @Input() hideRemoveIcon: boolean = false;
  @Input() backgroundColor: FileBackgroudColor = FileBackgroudColor.WHITE;
  @Output() onFileClickEvent = new EventEmitter<FileData>();
  @Input() loading: boolean;

  protected readonly IconSize = IconSize;

  removeFile(file: FileData) {
    this.onRemoveFile.emit(file);
  }

  isValidImageFormat(file: FileData): boolean {
    // List of valid MIME types for images
    const validImageTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg+xml',
      'image/bmp',
      'image/x-icon',
      'image/vnd.microsoft.icon',
      'image/webp',
      'image/tiff',
      'image/heic',
    ];
    return validImageTypes.includes(file['mime']);
  }

  onFileClick() {
    if (this.allowDownload) {
      this.onFileClickEvent.emit(this.file);
    }
  }
}
