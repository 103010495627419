import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EPermissions } from '@auth/login';
import { EmssHtmlPageService, PermissionService } from '@shared/services/util';
import { LanguageService } from '@shared/translate/language.service';
import { forkJoin, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ClassSelectors, FaqElement, Link, PageType } from '../data/wiki.model';

/**
 * <b> This is just for internal use.
 * DO NOT USE THIS COMPONENT DIRECTLY </b>
 */
@Component({
  selector: 'eop-wiki',
  template: '',
})
export class WikiComponent implements OnInit, OnDestroy {
  readonly headerSelectors = `.${ClassSelectors.WIKI_MARKDOWN_H1},.${ClassSelectors.WIKI_MARKDOWN_H2}`;

  replay$ = new ReplaySubject(1);
  fragment: string;
  headerLinks: Link[] = [];
  unsubscribe$ = new Subject<void>();
  linkInFocusId: string;
  elementsWithPermissions: HTMLElement[] = [];
  innerHTML: string = '<div></div>';
  faqElements: FaqElement[] = [];

  private _pageType = PageType.USER_MANUAL;
  protected set pageType(pageType: PageType) {
    this._pageType = pageType;
  }
  protected get pageType() {
    return this._pageType;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    const links = Array.from(document.querySelectorAll(this.headerSelectors));
    const linksInTop = links.filter(node => node.getBoundingClientRect().top <= 1);
    const linkInFocus = linksInTop[linksInTop.length - 1];
    if (linkInFocus) {
      this.linkInFocusId = linkInFocus.id;
    } else if (links.length > 0) {
      this.linkInFocusId = links[0].id;
    }
  }

  constructor(
    private el: ElementRef,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    public permissionService: PermissionService,
    public languageService: LanguageService,
    public emssHtmlPageService: EmssHtmlPageService,
    @Inject(DOCUMENT) public document: Document
  ) {}

  ngOnInit(): void {
    this.subscribeRouteFragment();
    this.processDocument();
  }

  processDocument(): void {
    this.getHtmlPage$()
      .pipe(
        tap((doc: string) => this.updateDocument(doc)),
        switchMap(() => this.imagesLoaded$()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.scrollIntoView());
  }

  subscribeRouteFragment(): void {
    this.route.fragment.pipe(takeUntil(this.unsubscribe$)).subscribe(fragment => {
      this.replay$.next(fragment);
    });
  }

  getHtmlPage$(): Observable<string> {
    return this.emssHtmlPageService.getHtmlPage(
      this.pageType,
      this.languageService.getUserLanguage()
    );
  }

  updateDocument(doc: string): void {
    this.innerHTML = doc;
    this.cdr.detectChanges();
    this.checkPermissions(this.document);
    this.headerLinks = this.createLinks(this.document);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.replay$.complete();
    this.document.body.classList.remove('no-scroll');
  }

  createLinks(document: Document): Link[] {
    let links: Link[] = [];
    const headers: HTMLElement[] = Array.from(document.querySelectorAll(this.headerSelectors));

    if (headers.length) {
      for (const header of headers) {
        if (
          header.parentElement.style.display === 'none' ||
          header.parentElement.parentElement.style.display === 'none'
        ) {
          continue;
        }
        const name = header.innerText.trim().replace(/^link/, '');
        links.push({
          active: false,
          name,
          type: header.className,
          id: header.id,
          subLinks: [],
        });
      }
      links = links.reduce(this.setSubLinks, []);
    }

    return links;
  }

  scrollIntoView(): void {
    this.replay$.pipe(takeUntil(this.unsubscribe$)).subscribe((fragment: string) => {
      const element = this.document.getElementById(`${fragment}`);
      this.fragment = fragment;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      }
    });
  }

  checkPermissions(document: Document): void {
    this.elementsWithPermissions = [];
    const userPermElements: HTMLElement[] = Array.from(
      document.querySelectorAll('div[data-user-permissions]')
    );

    userPermElements.forEach(userPermElement => {
      const permissions = userPermElement.dataset.userPermissions.split(',').filter(perm => {
        return this.permissionService.userHasPermission(<EPermissions>perm.trim());
      });
      if (permissions.length > 0) {
        this.elementsWithPermissions.push(userPermElement);
      } else {
        userPermElement.style.display = 'none';
      }
    });
  }

  transformHtmlToObject(): void {
    this.faqElements = [];
    this.elementsWithPermissions.forEach(elem => {
      Array.from(elem.children).forEach(faqElement => {
        this.faqElements.push(this.prepareFaqElement(<HTMLElement>faqElement));
      });
    });
  }

  private prepareFaqElement(faqElementHtml: HTMLElement): FaqElement {
    const titleElement = faqElementHtml.firstElementChild;
    const faqElement: FaqElement = {
      id: titleElement.id,
      title: titleElement.outerHTML,
      type: titleElement.className,
      questions: [],
      isExpandable: titleElement.attributes.getNamedItem('expandable')?.value === 'true',
    };
    Array.from(faqElementHtml.querySelectorAll('.question')).forEach(question => {
      const pagesId = question['dataset'].pagesId;
      faqElement.questions.push({
        question: question.firstElementChild.innerHTML,
        answer: question.getElementsByTagName('div')[0].innerHTML,
        pagesId: pagesId ? pagesId.split(',') : [titleElement.id],
      });
    });
    return faqElement;
  }

  private setSubLinks(previousValue: Link[], currentValue: Link) {
    if (currentValue.type === ClassSelectors.WIKI_MARKDOWN_H1) {
      return [...previousValue, currentValue];
    } else {
      const parent = previousValue[previousValue.length - 1];
      parent.subLinks.push(currentValue);
      return previousValue;
    }
  }

  private loadImage$(imagePath: string): Observable<HTMLImageElement> {
    return Observable.create((observer: Subject<HTMLImageElement>) => {
      const img = new Image();
      img.src = imagePath;
      img.onload = function () {
        observer.next(img);
        observer.complete();
      };
      img.onerror = err => {
        observer.error(err);
      };
    });
  }

  private imagesLoaded$(): Observable<HTMLImageElement[]> {
    const imgPaths = Array.from(this.el.nativeElement.querySelectorAll('img')).map(
      (el: HTMLImageElement) => el.src
    );
    return forkJoin(from(imgPaths).pipe(mergeMap(this.loadImage$)));
  }
}
