import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BackendFaultTypes, ErrorResponse } from '@shared/errors/error.model';
import { UserDateTimePipe } from '@shared/pipes';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  readonly DEFAULT_ERROR_MESSAGE_KEY = 'GLOBAL.ERROR_MSG';

  constructor(
    private translate: TranslateService,
    private userDateTimePipe: UserDateTimePipe
  ) {}

  public parseErrorIntoMessage(errorResponse: ErrorResponse): string {
    let message = this.findTranslationByErrorModel(errorResponse);
    const traceId = errorResponse.error?.traceId;
    if (traceId) {
      const errorIdLabel = this.translate.instant('GLOBAL.ERROR_ID');
      message = `${message} (${errorIdLabel}: ${traceId})`;
    }
    const timestamp = this.userDateTimePipe.transform(moment().format());
    message = `${message} - ${timestamp}`;
    return message;
  }

  /*
   * compare error key with error response
   * errorKey = faultType + '.' + fault
   * errorKey example: 'ContractFault.EMP_UNKNOWN'
   */
  public errorEquals(faultType: BackendFaultTypes, fault: string, error: ErrorResponse): boolean {
    const errorModel = error.error;
    if (errorModel && errorModel.faultType && errorModel.fault) {
      return errorModel.faultType === faultType && errorModel.fault === fault;
    } else {
      return false;
    }
  }

  private findTranslationByErrorModel(errorResponse: ErrorResponse) {
    const errorModel = errorResponse.error;
    if (!(errorModel && errorModel.faultType) || !(errorModel && errorModel.fault)) {
      return this.translate.instant(this.DEFAULT_ERROR_MESSAGE_KEY);
    }
    // try to find a translated message, if not found use default message
    const translationKey = errorModel.faultType + '.' + errorModel.fault;
    let message = this.translate.instant(translationKey);

    if (translationKey === message) {
      // no translation found
      console.log('No translation found for error key: ' + translationKey);
      message = this.translate.instant(this.DEFAULT_ERROR_MESSAGE_KEY);
    }
    return message;
  }
}
