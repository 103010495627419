import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
  ConfirmDialogWithTitleIconText,
  ConfirmDialogWithTitleIconTextModel,
  DeleteDialogComponent,
  DeleteDialogModel,
  InfoDialogComponent,
  InfoDialogModel,
} from '@widgets/dialogs';
import { IconColor } from '@widgets/eop-icon';
import { ITableConfig, ITableData } from '@widgets/innogy-data-table';
import { SharedRefService } from '@widgets/shared-ref-service/shared-ref-service.service';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(
    public dialog: MatDialog,
    private sharedRefService: SharedRefService
  ) {}

  /**
   * Opens info dialog where user have to click on OK button
   */
  openInfoDialog(
    title: string,
    message: string,
    titleIconIdentifier?: string,
    bulletPoints?: string[],
    titleIconColor?: IconColor
  ): Observable<boolean> {
    const dialog = this.dialog
      .open(InfoDialogComponent, {
        data: <InfoDialogModel>{
          title,
          message,
          titleIconIdentifier,
          bulletPoints,
          titleIconColor,
        },
        width: '600px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        tap(() => this.sharedRefService.closeDialog())
      );

    this.sharedRefService.openDialog(dialog);
    return dialog;
  }

  openInfoDialogWithTable(
    title: string,
    message: string,
    titleIconIdentifier?: string,
    titleIconColor?: IconColor,
    tableConfig?: ITableConfig,
    tableData?: ITableData
  ): Observable<boolean> {
    const dialog = this.dialog
      .open(InfoDialogComponent, {
        data: <InfoDialogModel>{
          title,
          message,
          titleIconIdentifier,
          titleIconColor,
          tableConfig,
          tableData,
        },
        width: '600px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        tap(() => this.sharedRefService.closeDialog())
      );

    this.sharedRefService.openDialog(dialog);
    return dialog;
  }

  /**
   * Opens confirm dialog and returns an observable with the users decision
   */
  openConfirmDialog(
    confirmMessage: string,
    info?: string,
    yesnoButtons: boolean = false,
    headline?: string,
    titleIconIdentifier?: string,
    titleIconColor?: IconColor
  ): Observable<boolean> {
    const dialog = this.dialog
      .open(ConfirmDialogComponent, {
        data: <ConfirmDialogModel>{
          message: confirmMessage,
          yesnoButtons,
          info,
          headline,
          titleIconIdentifier,
          titleIconColor,
        },
        width: '500px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        tap(() => this.sharedRefService.closeDialog())
      );

    this.sharedRefService.openDialog(dialog);
    return dialog;
  }

  openConfirmDialogWithTitleIconText(
    title: string,
    icon: string,
    message: string
  ): Observable<boolean> {
    const dialog = this.dialog
      .open(ConfirmDialogWithTitleIconText, {
        data: <ConfirmDialogWithTitleIconTextModel>{
          title,
          icon,
          message,
        },
        width: '800px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        tap(() => this.sharedRefService.closeDialog())
      );

    this.sharedRefService.openDialog(dialog);
    return dialog;
  }

  /**
   * Opens delete dialog and returns an observable with the users decision
   */
  openDeleteDialog(objectToDeleteName: string, customMessage?: string): Observable<boolean> {
    const dialog = this.dialog
      .open(DeleteDialogComponent, {
        data: <DeleteDialogModel>{
          objectToDeleteName,
          message: customMessage,
        },
        width: '600px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        tap(() => this.sharedRefService.closeDialog())
      );

    this.sharedRefService.openDialog(dialog);
    return dialog;
  }
}
