export interface LiteChipsData {
  text: string;
  chipColor: ChipLiteColor;
}

export enum ChipLiteColor {
  DEFAULT = 'chip-color-default',
  SUCCESS = 'chip-color-success',
  ERROR = 'chip-color-error',
  WARNING = 'chip-color-warning',
  INFO = 'chip-color-info',
  BRANDING = 'chip-color-branding',
  BRANDING_BASE = 'chip-color-branding-base',
  LIGHT = 'chip-color-light',
}

export enum ChipLiteSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  BIG = 'BIG',
}
