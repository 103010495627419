import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NotificationLevel } from '@shared/services/notification';
import { environment } from 'environments/environment';

@Component({
  selector: 'notification-top',
  templateUrl: './notification-top.component.html',
  styleUrls: ['./notification-top.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationTopComponent implements AfterViewInit, OnDestroy {
  public NotificationLevel = NotificationLevel;
  @Input()
  level: NotificationLevel;
  @Input()
  message: string;
  @Input()
  traceId: string;
  @Input()
  closeButtonText: string;
  @Input()
  reloadButtonText: string;
  @Output()
  closeNotification: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('notificationRef') private notificationTemplateRef;
  overlayRef: OverlayRef;

  linkToGrafana = !environment.production;

  constructor(
    private overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    this.createOverlayAndAttachContent();
  }

  onClose(): void {
    this.closeNotification.emit();
    this.overlayRef.dispose();
  }

  /**
   * Only for Non-Prod!
   *
   * Link to Grafana with predefinied query
   * {environment="int"} |= "4b3d39b2bd5bf5db" | json | line_format "{{.service}} = {{.message}}"
   */
  openGrafana(): void {
    const env = environment.int ? 'int' : 'qa';
    const link = `https://grafana.asgard.internal.services-emobility.com/explore?orgId=1&left=%7B%22datasource%22:%22loki-nonprod%22,%22queries%22:%5B%7B%22expr%22:%22%7Benvironment%3D%5C%22${env}%5C%22%7D%20%7C%3D%20%5C%22${this.traceId}%5C%22%20%7C%20json%20%7C%20line_format%20%5C%22%7B%7B.service%7D%7D%20%3D%20%7B%7B.message%7D%7D%5C%22%22,%22refId%22:%22A%22%7D%5D,%22range%22:%7B%22from%22:%22now-5m%22,%22to%22:%22now%22%7D%7D`;
    window.open(link, '_blank');
  }

  ngOnDestroy(): void {
    this.overlayRef.dispose();
  }

  onReloadButtonClick(): void {
    window.location.reload();
  }

  private createOverlayAndAttachContent(): void {
    this.overlayRef = this.overlay.create({
      panelClass: 'cdk-overlay-pane--notification-overlay',
    });
    this.overlayRef.attach(new TemplatePortal(this.notificationTemplateRef, this.viewContainerRef));
  }
}
