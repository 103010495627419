<ng-template #notificationRef>
  <div
    class="notification-top"
    [ngSwitch]="level"
    [ngClass]="{
      error: level === NotificationLevel.ERROR,
      success: level === NotificationLevel.SUCCESS,
      warning: level === NotificationLevel.WARNING,
    }"
  >
    <!-- ERROR -->
    <div
      class="notification"
      [ngClass]="{
        error: level === NotificationLevel.ERROR,
        success: level === NotificationLevel.SUCCESS,
        warning: level === NotificationLevel.WARNING,
      }"
    >
      <div class="text">
        {{ message }}
      </div>
      <div class="buttons-wrapper">
        <ng-container *ngTemplateOutlet="closeButton"></ng-container>
        <div *ngIf="reloadButtonText">
          <ng-container *ngTemplateOutlet="reloadButton"></ng-container>
        </div>
        <div *ngIf="linkToGrafana && traceId">
          <ng-container *ngTemplateOutlet="link"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #closeButton>
    <div
      *ngIf="!reloadButtonText"
      class="btn"
      [ngClass]="{
        'btn--error': level === NotificationLevel.ERROR,
        'btn--success': level === NotificationLevel.SUCCESS,
        'btn--warning': level === NotificationLevel.WARNING,
      }"
      (click)="onClose()"
    >
      <span>
        {{ closeButtonText }}
      </span>
    </div>
  </ng-template>
  <ng-template #reloadButton>
    <div class="btn" (click)="onReloadButtonClick()">
      <span>
        {{ reloadButtonText }}
      </span>
    </div>
  </ng-template>
  <ng-template #link>
    <div class="btn btn--more-info" (click)="openGrafana()">
      <span> Grafana </span>
    </div>
  </ng-template>
</ng-template>
