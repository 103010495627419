<div
  class="days-container"
  [ngStyle]="{
    '--activeBorderColor': options.activeBorderColor,
    '--inactiveBorderColor': options.inactiveBorderColor,
    '--activeColor': options.activeColor,
    '--activeBgColor': options.activeBgColor,
    '--inactiveColor': options.inactiveColor,
    '--inactiveBgColor': options.inactiveBgColor,
  }"
>
  <button
    *ngFor="let d of days"
    (click)="dayClicked(d)"
    [ngClass]="{ active: d.isSelected, disabled: d.isDisabled, readonly: readonly }"
  >
    {{ d.name }}
  </button>
</div>
