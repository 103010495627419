import {
  Component,
  EventEmitter,
  Input,
  booleanAttribute,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IconSize, IconWeight } from '@widgets/eop-icon';
import { ButtonColor } from '@widgets/eop-button/eop-button.component';

export enum ProgressStates {
  Begin = 'begin',
  Sent = 'sent',
  Completed = 'completed',
}

@Component({
  selector: 'save-button',
  templateUrl: 'save-button.component.html',
  styleUrls: ['save-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SaveButtonComponent implements OnInit {
  protected readonly ButtonColor = ButtonColor;
  public ProgressStates = ProgressStates;

  @Input()
  public progressState: ProgressStates = ProgressStates.Begin;

  @Input()
  public disabled: boolean;

  @Input()
  public buttonText: string;

  @Input()
  public buttonColor = ButtonColor.PRIMARY;

  @Input()
  public icon: string;

  @Input({ transform: booleanAttribute })
  public vaylensFont: boolean;

  @Input()
  public iconSize: IconSize = IconSize.NORMAL;

  @Output()
  public progressStateChange: EventEmitter<ProgressStates> = new EventEmitter<ProgressStates>();

  readonly IconWeight = IconWeight;

  constructor(private translate: TranslateService) {}

  public ngOnInit(): void {
    if (!this.buttonText) {
      this.buttonText = this.translate.instant('GLOBAL.SAVE');
    }
  }

  public emitButtonClicked() {
    this.progressState = ProgressStates.Sent;
    this.progressStateChange.emit(this.progressState);
  }
}
