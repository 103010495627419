import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { EopIconModule } from '@widgets/eop-icon/eop-icon.module';
import { LoadingIndicatorModule } from '../loading-indicator/loading-indicator.module';
import { DropdownOverlayContentDirective } from './dropdown-overlay/dropdown-overlay-content.directive';
import { DropdownOverlayComponent } from './dropdown-overlay/dropdown-overlay.component';
import { DropdownOverlayDirective } from './dropdown-overlay/dropdown-overlay.directive';
import { PopoverService } from './dropdown-overlay/popover.service';
import { DropdownComponent } from './dropdown.component';
import { ErrorKeyPipe } from './error-key.pipe';
import { FilterPipe } from './filter.pipe';
import { OptionComponent } from './option/option.component';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    ReactiveFormsModule,
    EopIconModule,
    FormsModule,
    LoadingIndicatorModule,
    ScrollingModule,
    MatTooltip,
  ],
  declarations: [
    DropdownComponent,
    OptionComponent,
    DropdownOverlayComponent,
    DropdownOverlayDirective,
    DropdownOverlayContentDirective,
    FilterPipe,
    ErrorKeyPipe,
  ],
  providers: [PopoverService],
  exports: [DropdownComponent],
})
export class DropdownModule {}
