import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@shared/translate/language.service';

@Pipe({
  name: 'results',
})
export class ResultsPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private languageService: LanguageService
  ) {}

  transform(value: number): string {
    switch (value) {
      case null: {
        return this.translate.instant('GLOBAL.LOADING_DATA');
      }
      case undefined: {
        return this.translate.instant('GLOBAL.NO_ENTRIES');
      }
      case 0: {
        return this.translate.instant('GLOBAL.NO_ENTRIES');
      }
      case 1: {
        return value + ' ' + this.translate.instant('GLOBAL.RESULT');
      }
      default: {
        return (
          formatNumber(value, this.languageService.getUserLanguageWithFallback(), '1.0') +
          ' ' +
          this.translate.instant('GLOBAL.RESULTS')
        );
      }
    }
  }
}
