import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[maskSeparator]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputMaskDirective),
      multi: true,
    },
  ],
})
export class InputMaskDirective implements ControlValueAccessor {
  @Input()
  maskSeparator: { separatorShow: string; toFixed: number };
  isDisabled: boolean;
  @HostListener('input', ['$event'])
  oninput($event) {
    const { separatorShow, toFixed } = this.maskSeparator;
    const v = $event.target.value;

    const sep = v.match(/\D+/);
    if (sep) {
      const sepChar = sep[0];
      const sepInput = sep['input'];
      const sepIndex = sep['index'];

      const newInputTmp = sepInput.replace(sepChar, separatorShow);
      const newInput = newInputTmp.substring(0, sepIndex + toFixed + 1);

      this.renderer.setProperty(this.elementRef.nativeElement, 'value', newInput);
    }

    const modelVal = v.replace(',', '.');
    this.registerOnChangeCb(modelVal);
  }

  @HostListener('blur', ['$event'])
  onBlur($event) {
    const { separatorShow, toFixed } = this.maskSeparator;
    const v = $event.target.value;
    const sep = parseFloat(v.replace(',', '.')) ? parseFloat(v.replace(',', '.')) : 0;
    const calcDec = Math.pow(10, toFixed);

    if (v === '') {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', '');
      return;
    }

    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      String(Math.trunc(Number(sep) * calcDec) / calcDec).replace('.', separatorShow)
    );
  }

  registerOnChangeCb = (e: any) => {};

  registerOnTouchedCb = (e: any) => {};

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  writeValue(value: any) {
    const { separatorShow, toFixed } = this.maskSeparator;
    const calcDec = Math.pow(10, toFixed);

    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'value',
      String(Math.trunc(Number(value) * calcDec) / calcDec).replace('.', separatorShow)
    );
  }

  registerOnChange(fn: any) {
    this.registerOnChangeCb = fn;
  }

  registerOnTouched(fn: any) {
    this.registerOnTouchedCb = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
