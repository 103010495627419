import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  GoogleAnalyticsAction,
  GoogleAnalyticsCategory,
  GoogleAnalyticsEventWrapper,
  GoogleAnalyticsLabel,
} from '@shared/google-analytics/google-analytics.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  shouldHaveGtmInitialized = !environment.int;
  private previousVisitedUrl: string;

  constructor(
    private router: Router,
    private gtmService: GoogleTagManagerService
  ) {}

  listenToRouteChanges() {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const url = this.getUrlWithoutQueryParams(item.url);
        if (this.previousVisitedUrl === url) {
          // Do not send same page visit event twice
          return;
        } else {
          this.previousVisitedUrl = url;
          this.sendEvent(
            GoogleAnalyticsCategory.PAGE_VISIT,
            GoogleAnalyticsAction.URL_CHANGED,
            url
          );
        }
      }
    });
  }

  sendEventForCsvDownload() {
    const page = this.getUrlWithoutQueryParams(this.router.url);
    this.sendEvent(
      GoogleAnalyticsCategory.CSV,
      GoogleAnalyticsAction.DOWNLOAD,
      page ? page : 'UNKNOWN_PAGE'
    );
  }

  sendEvent(
    category: GoogleAnalyticsCategory,
    action: GoogleAnalyticsAction,
    label: GoogleAnalyticsLabel | string
  ) {
    const gtmTag: GoogleAnalyticsEventWrapper = {
      event: [
        {
          eventInfo: {
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
          },
        },
      ],
    };

    if (this.shouldHaveGtmInitialized) {
      this.gtmService.pushTag(gtmTag);
    } else {
      console.debug('Sending Google Analytics Event: ', gtmTag.event[0].eventInfo);
    }
  }

  private getUrlWithoutQueryParams(url: string): string {
    return url.split('?')[0];
  }
}
