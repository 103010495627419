import { CurrencyPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  PriceOffset,
  PriceType,
  TariffPrice,
} from '../../clearing-house/clearing-house-tariffs/data/clearing-house-tariffs.models';
import { Language } from '@auth/login';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'tariffValuesTableRowPipe',
})
export class TariffTableRowPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe
  ) {}
  transform(tariffPrice: TariffPrice, currencyCode: string, locale: string): string {
    let valueWithSymbol: string;
    if (tariffPrice.offset) {
      valueWithSymbol = this.getValueWithSymbolByOffset(
        tariffPrice.value,
        tariffPrice.offset,
        currencyCode,
        locale
      );
    } else {
      valueWithSymbol = this.getValueWithCurrencySymbol(tariffPrice.value, currencyCode, locale);
    }

    return `${valueWithSymbol}/${this.getPriceTypeText(tariffPrice.priceType, tariffPrice.delay)}`;
  }

  private getPriceTypeText(priceType: PriceType, delay: number): string {
    switch (priceType) {
      case PriceType.CHARGING_KWH:
        return this.translate.instant('PRIC_MDP_TABLE.UNIT_KWH');
      case PriceType.CHARGING_TIME:
        return delay
          ? `${this.translate.instant('PRIC_MDP_TABLE.UNIT_MINUTE')} ${this.translate.instant(
              'GLOBAL.AFTER'
            )} ${delay} ${this.getMinuteTranslation(delay)}`
          : this.translate.instant('PRIC_MDP_TABLE.UNIT_MINUTE');
      case PriceType.CHARGING_SESSION:
        return this.translate.instant('PRIC_MDP_TABLE.UNIT_SESSION');
      default:
        return '-';
    }
  }

  private getMinuteTranslation(delay: number): string {
    return delay === 1
      ? this.translate.instant('PRIC_MDP_TABLE.UNIT_MINUTE')
      : this.translate.instant('PRIC_MDP_TABLE.UNIT_MINUTES');
  }

  private getValueWithSymbolByOffset(
    value: number,
    offset: PriceOffset,
    currencyCode: string,
    locale: string
  ): string {
    switch (offset) {
      case PriceOffset.NONE:
        return this.getValueWithCurrencySymbol(value, currencyCode, locale);
      case PriceOffset.ABSOLUTE:
        return `+${this.getValueWithCurrencySymbol(value, currencyCode, locale)}`;
      case PriceOffset.RELATIVE:
        return `+${value}%`;
      default:
        return '-';
    }
  }

  private getValueWithCurrencySymbol(value: number, currencyCode: string, locale: string): string {
    return this.currencyPipe.transform(
      value,
      currencyCode,
      'symbol',
      // max decimal places is set to 15 (it is not expected to have more)
      '1.2-15',
      locale === Language.Empty ? undefined : `${locale}-${locale.toUpperCase()}`
    );
  }
}
