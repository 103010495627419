import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { IUserLogin } from '@auth/login';
import { AuthenticationService } from '@shared/services/auth';
import { SessionFacade } from '@shared/store/session.facade';
import { isEmpty } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AccessGuardLogged {
  constructor(
    private sessionFacade: SessionFacade,
    private authService: AuthenticationService,
    private router: Router
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.checkLoginInStore(state);
  }

  private checkLoginInStore(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.sessionFacade.userLogin$.pipe(
      take(1),
      switchMap((userLogin: IUserLogin) => {
        if (isEmpty(userLogin)) {
          return this.checkLoginFromBackend$().pipe(
            map(userLoginData => {
              if (isEmpty(userLoginData)) {
                return this.router.parseUrl('/auth/login');
              } else {
                return true;
              }
            })
          );
        } else {
          return this.navigateLoggedIn(state);
        }
      }),
      catchError(err => {
        return this.redirectToAuthPage(state);
      })
    );
  }

  private checkLoginFromBackend$(): Observable<IUserLogin> {
    // Login user if he has a valid session
    return this.authService.getCurrentUserLogin().pipe(
      take(1),
      tap(userLogin => {
        this.sessionFacade.loginSuccess(userLogin);
      })
    );
  }

  private redirectToAuthPage(state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    const currentUrl = state.url.split('/');
    if (currentUrl.includes('auth')) {
      return of(true);
    }
    this.sessionFacade.saveUnauthNavigatedPageToStore(state.url);
    return of(this.router.parseUrl('/auth/login'));
  }

  private navigateLoggedIn(state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    const currentUrl = state.url.split('/');
    if (currentUrl.includes('auth')) {
      return of(this.router.parseUrl('/monitor/dashboard'));
    }
    return of(true);
  }
}
