import { ValidatorFn } from '@angular/forms';
import { ChipLiteColor } from '@widgets/innogy-chips-lite/data/innogy.chips-lite.data';

export interface IColumn {
  id: string;
  value: string;
  class?: string;
  visibleInHeader?: boolean;
  visibleInDetails?: boolean;
  visibleFor?: string;
  type?: string;
  format?: string;
  adaptUserTimeZone?: boolean;
  url?: string;
  sortable?: boolean;
  filterable?: boolean;
  showToolTip?: boolean;
  headerTooltip?: string;
  chipSettings?: TableChipSettings;
  shouldTextCrop?: boolean;
  limitHeight?: boolean;
  maxInputLength?: number;
  inputPlaceholder?: string;
  validators?: ValidatorFn[];
  width?: string;
}

export interface TableChipSettings {
  chipColor?: ChipLiteColor;
  chipTextMaxLength?: number;
  chipListCollapsable?: boolean;
  chipEmphasized?: boolean;
}

export interface InputChangedData {
  id: string;
  newValue: string;
}
